<template>
  <div>
    <el-upload
      action=""
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :http-request="upload"
      :limit="1"
      :file-list="fileList"
    >
      上传logo
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img class="img" width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { apiUpload } from '@/api/demand'
export default {
  components: {},
  props: {
    imageShow: {
      type: String
    }
  },
  watch: {
    imageShow(val) {
      // console.log(val)
      this.fileList = []
      if (val) {
        this.fileList.push({
          name: val,
          url: val
        })
      }
    }
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: []
    }
  },

  computed: {},

  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    async upload(params) {
      // console.log(params.file)
      const form = new FormData()
      form.append('file', params.file)
      // console.log(form.get('file'))
      const res  = await apiUpload(form)
      // console.log(res)
      this.$emit('uploadOneImgShow', res.data)
    },
    handleRemove(file, fileList) {
      // console.log(file)
      // console.log(fileList)
      this.$emit('uploadOneImgShow', null)
    }
  },

  created() {
    this.dialogImageUrl = this.imageShow
  },

  mounted() {}
}
</script>
<style scoped lang="less">
/deep/.el-upload--picture-card {
  width: 72px;
  height: 33px;
  background-color: rgba(78, 147, 251, 0.1);
  border-radius: 2px;
  border: none;
  text-align: center;
  line-height: 33px;
  font-size: 14px;
  color: #4e93fb;
  display: inline-block;
}
/deep/.el-upload-list__item {
  width: 80px;
  height: 80px;
  border: none;
}
/deep/.el-dialog {
  width: 500px;
  height: 500px !important;
}
/deep/.el-dialog__body {
  width: 500px;
  height: 500px !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-dialog__header {
  padding: 0;
}
.img {
  width: 450px;
  height: 450px;
}
/deep/ .el-upload-list__item.is-ready {
  display: none;
}
</style>
