<template>
  <div :class="{ hidden: hidden }">
    <el-upload
      action=""
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :http-request="upload"
      :file-list="fileLists"
      :on-remove="handleRemove"
      :limit="1"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img class="img" width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { apiUpload } from '@/api/demand'
export default {
  components: {},
  props: {
    fileList: {
      type: Array
    }
  },

  data() {
    return {
      dialogImageUrl: [],
      dialogVisible: false,
      fileLists: [],
      imageList: []
    }
  },
created(){
  this.fileLists=[]
  this.fileLists.push({
          name: this.fileList[0],
          url: this.fileList[0]
        })
       
},
watch: {
  fileList(val) {
    this.fileLists=[]
        this.fileLists.push({
          name: this.fileList[0],
          url: this.fileList[0]
        })
      
    }
  },
  computed: {
    hidden() {
      console.log(this.fileLists, 'this.fileLists')
      return this.fileLists.length
    }
  },

  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    async upload(params) {
      const form = new FormData()
      form.append('file', params.file)
      const  res = await apiUpload(form)

      this.fileLists.push({
        url: res.data,
        uid: params.file.uid
      })

     this.$emit('uploadOneImg', this.fileLists) 
    },
    handleRemove(file, fileList) {
      this.fileLists.forEach((item, index) => {
        if (item.uid === file.uid) {
          this.fileLists.splice(index, 1)
        }
      })

      this.$emit('removeImg', fileList)
    }
  },

  

  mounted() {}
}
</script>
<style scoped lang="less">
/deep/.el-upload--picture-card {
  width: 238px;
  height: 134px;
  border-radius: 2px;
  border: none;
  text-align: center;
  line-height: 134px;
  font-size: 14px;
  color: #4e93fb;
  display: inline-block;
  background-color: #f4f4f4;
}
.hidden /deep/.el-upload--picture-card {
  display: none;
}
/deep/.el-upload-list__item {
  width: 238px;
  height: 134px;
  border: none;
}
/deep/.el-dialog {
  width: 500px;
  height: 500px !important;
}
/deep/.el-dialog__body {
  width: 500px;
  height: 500px !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-dialog__header {
  padding: 0;
}
.img {
  width: 450px;
  height: 450px;
}
</style>
